<template>
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <div class="title wow animated fadeInDown">{{$t('login.register')}}</div>

                <div class="form_box wow animated fadeIn">
                    <div class="inp_label">{{$t('common.userName')}}</div>
                    <input v-model="account" class="inp" type="text" data-index="username"
                        :placeholder="$t('tip.accountPlaceHolder')" />

                    <!-- 邮箱验证码 -->
                    <div class="inp_label">{{$t('common.email')}}</div>
                    <input v-model="email" class="inp" type="text" data-index="email"
                        :placeholder="$t('tip.emailEmpty')" />
                    <div class="inp_label">{{$t('common.verification')}}</div>
                    <div class="inp_code">
                        <input :placeholder="$t('common.inputVerification')" type="text" v-model="sms_code"
                            class="inp" />
                        <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s
                        </div>
                        <div v-else class="g_btn btn" @click="handleSendCode">
                            <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                            <template v-else>{{$t('common.resend')}}</template>
                        </div>
                    </div>

                    <div class="inp_label">{{$t('common.pwd')}}</div>
                    <input v-model="password1" class="inp" type="password" data-index="password"
                        :placeholder="$t('tip.commonVer')" />

                    <div class="inp_label">{{$t('login.confirm')}}</div>
                    <input v-model="password2" class="inp" type="password" data-index="password"
                        :placeholder="$t('tip.commonVer')" />

                    <input v-model="code" class="inp" type="text" data-index="password"
                        :placeholder="$t('tip.invite')" />

                    <el-button @click="register" class="btn login" :loading="loading">{{$t('common.register')}}
                    </el-button>
                    <router-link to="/login" class="btn btn-o">
                        <span class="gradient">{{$t('login.toLogin')}}</span>
                    </router-link>
                    <div class="fd">
                        <label for="readme">
                            <input v-model="checked" type="checkbox" name="readme" id="readme"
                                class="checkbox">{{$t('login.agree')}}
                        </label>
                        <router-link class="gradient" to="/agreement">《{{$t('common.agreement')}}》</router-link>
                    </div>
                </div>
                <div class="login_footer wow animated fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
    let timer;
    import { Message } from 'element-ui'
    import { WOW } from 'wowjs'
    import { mapGetters } from 'vuex';
    // api
    import { sendEmail } from '@/api/user'
    export default {
        data() {
            return {
                loading: false,

                account: '',
                email: '',
                sms_code: '',
                password1: '',
                password2: '',
                code: '',
                checked: false,

                // 邮箱验证
                codeSending: false,
                timeCount: 60,
                firstSend: true,
            }
        },
        computed: {
            ...mapGetters(['version', 'isTwitter'])
        },
        mounted() {
            this.$nextTick(() => {
                this.wow();
            })
        },
        methods: {
            wow() {
                if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                    var wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 50,
                        mobile: true,
                        live: true
                    });
                    this.$nextTick(() => {
                        wow.init();
                    });
                };
            },
            // 发送验证码
            handleSendCode() {
                if (!this.codeSending) {
                    var emailReg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
                    // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if (!this.account) {
                        this.$message({
                            message: this.$t('tip.usernameEmpty') + '！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    } else if (!this.email) {
                        this.$message({
                            message: this.$t('tip.emailEmpty') + '！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    } else if (!emailReg.test(this.email)) {
                        this.$message({
                            message: this.$t('tip.emailVer') + '！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    } else {
                        // 发送验证码
                        this.timeCount = 60;
                        this.codeSending = true;
                        this.firstSend = false;
                        clearInterval(timer);
                        timer = setInterval(() => {
                            if (this.timeCount >= 1) {
                                this.timeCount -= 1;
                            } else {
                                clearInterval(timer);
                                this.codeSending = false;
                            }
                        }, 1000);

                        sendEmail({
                            account: this.account,
                            email: this.email,
                            type: 3,
                        })
                    }
                }
            },
            register() {
                if (this.loading) {
                    return
                }
                // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                // var pwdReg=/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/
                const reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
                const pwdReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+=\[\]{};:'"\\|,<.>/?]).{8,}$/;
                var emailReg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

                let account = this.account.trim()
                if (!account) {
                    this.$message({
                        message: this.$t('tip.usernameEmpty') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!reg.test(account) || account.length < 6) {
                    this.$message({
                        message: this.$t('tip.accountVer') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!this.email) {
                    this.$message({
                        message: this.$t('tip.emailEmpty') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!emailReg.test(this.email)) {
                    this.$message({
                        message: this.$t('tip.emailVer') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!this.sms_code) {
                    this.$message({
                        message: this.$t('tip.codeEmpty') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!this.password1) {
                    this.$message({
                        message: this.$t('tip.pwdEmpty1') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!pwdReg.test(this.password1)) {
                    this.$message({
                        message: this.$t('tip.pwdVer') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (this.password1 != this.password2) {
                    this.$message({
                        message: this.$t('tip.pwd2') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!this.checked) {
                    this.$message({
                        message: this.$t('tip.check') + '！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else {
                    var register_source = '0';
                    if (this.isTwitter == 1) {
                        register_source = 'twitter'
                    } else if (this.isTwitter == 2) {
                        register_source = 'qrcode'
                    }
                    this.loading = true

                    this.$store.dispatch('user/register', {
                        account,
                        password: this.password2,
                        code: this.code,
                        register_source,
                        email: this.email,
                        sms_code: this.sms_code
                    }).then(res => {
                        this.loading = false;
                        this.$message({
                            message: this.$t('tip.registerSuc'),
                            center: true,
                            type: 'success',
                            customClass: 'shotMsg',
                        });
                        this.$router.push({
                            name: 'Account',
                            params:{isRegister:'1'}
                        })
                    }).catch(() => {
                        this.loading = false;
                    })
                }
            },
        },
        created() {
            if (this.$route.query.code) {
                this.code = this.$route.query.code;
            }
        },
        beforeDestroy() {
            if (timer) {
                clearInterval(timer)
                timer = ''
            }
        }
    }
</script>
<style scoped="scoped" lang="stylus">
    @import '~@/views/login/login.styl';
    @import '~@/assets/style/frames.styl';
</style>